import { UserRole } from '@common/clients/api';

/** Moderator access includes moderators and admins roles, _2 is for moderator, _4 is for admin */
const MODERATOR_AND_ADMIN_ACCESS: UserRole[] = [UserRole.MODERATOR, UserRole.ADMIN];
export const hasModeratorOrAdminAccess = (role: UserRole | undefined) => {
    if (!role) return false;
    return MODERATOR_AND_ADMIN_ACCESS.includes(role);
};

export const EDITOR_AND_ADMIN_ACCESS: UserRole[] = [UserRole.EDITOR, UserRole.ADMIN];
export const hasEditorOrAdminAccess = (role: UserRole | undefined) => {
    if (!role) return false;
    return EDITOR_AND_ADMIN_ACCESS.includes(role);
};

/** Editor access includes _3 - editor role; the difference between moderator access and
 * editor access is that editors cannot edit comments */
export const EDITOR_ACCESS: UserRole[] = [UserRole.EDITOR];
export const hasEditorAccess = (role: UserRole | undefined) => {
    if (!role) return false;
    return EDITOR_ACCESS.includes(role);
};

const ELEVATED_ACCESS: UserRole[] = [UserRole.MODERATOR, UserRole.EDITOR, UserRole.ADMIN];
export const hasElevatedAccess = (role: UserRole | undefined) => {
    if (!role) return false;
    return ELEVATED_ACCESS.includes(role);
};
