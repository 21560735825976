export const getAdminUrl = (contextUrl: string) => {
    const isDevMode = (process.env.APP_ENV || process.env.NODE_ENV) === 'development';
    const protocol = isDevMode ? 'http' : 'https';

    const domain = `admin.${contextUrl.replace('www.', '')}`;

    const adminUrl = `${protocol}://${domain}`;

    return adminUrl;
};
