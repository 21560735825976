import { FC, useMemo, useState } from 'react';
import { orderBy } from 'lodash';

import { Icon } from '@common/atoms/Icon';
import { AlternateLink } from '@common/clients/api';
import { useContextData } from '@common/useContextData';
import { MediaQuery, MediaQueryDirection, useResponsive } from '@web/hooks/useResponsive/useResponsive';
import { MainDropdown } from '@web/molecules/MainDropdown';
import { Item as MainDropDownItem } from '@web/molecules/MainDropdown/types';

import styles from './LanguageSelector.module.scss';

export interface Props {
    isInverted?: boolean;
}

export const LanguageSelector: FC<Props> = ({ isInverted }) => {
    const { context, contexts, alternateLinks } = useContextData();
    const [selectedLanguage, setSelectedLanguage] = useState<string>(
        context.slug || contexts[0]?.slug || '-',
    );

    const header = (
        <span className={styles.header}>
            <Icon.globe />
            {selectedLanguage}
        </span>
    );

    const getLocaleLink = (
        currentUrl: string,
        locale: string,
        languageShortName: string,
        alternateLinks?: AlternateLink[],
    ) => {
        if (!alternateLinks) return `/${languageShortName}`;
        const alternateLinkForLocale = alternateLinks.find((link) => link.locale === locale);
        if (
            !alternateLinkForLocale ||
            !alternateLinkForLocale.host ||
            !alternateLinkForLocale.host.includes(currentUrl)
        )
            return `/${languageShortName}`;

        // This wont work on abnormal urls ie things with ports or next.whatever, but it's a lot to support those dev environments and doesn't make much sense
        return `${alternateLinkForLocale.host}${alternateLinkForLocale.path}`;
    };

    const getLanguageContext = (languageName: string) => {
        return contexts?.find((context) => context.languageName === String(languageName));
    };

    const handleLanguageIsChanged = async (selectedItem: MainDropDownItem) => {
        const languageContext = getLanguageContext(String(selectedItem));
        const locale = languageContext?.locale ?? '-';
        const languageShortName = languageContext?.slug || '-';
        setSelectedLanguage(languageShortName);
        const localeLink = getLocaleLink(context.url, locale, languageShortName, alternateLinks);

        // Doing a window.location.href instead of a router.push because it's not worth the complexity of parsing alternateLinks to be what next router wants...
        // Also since the host could be totally different next router might not even work
        window.location.href = localeLink;
    };

    const items = useMemo(
        () =>
            orderBy(
                contexts,
                [(item) => (item.slug === selectedLanguage ? 0 : 1), (item) => item],
                ['asc', 'asc'],
            ).map((context) => context.languageName),
        [contexts, selectedLanguage],
    );

    const isMobileView = useResponsive({
        direction: MediaQueryDirection.below,
        size: MediaQuery.l,
    });

    return (
        <span className={styles.LanguageSelector}>
            <MainDropdown
                items={items}
                header={header}
                onChangeDropdown={handleLanguageIsChanged}
                isInverted={isInverted}
                isExpandToTop={isMobileView}
            />
        </span>
    );
};
