import { ParsedUrlQuery } from 'querystring';

export class QueryUtil {
    public static getQueryParam = (query: ParsedUrlQuery, key: string): string | undefined => {
        const value = query[key];
        if (Array.isArray(value)) {
            if (value.length !== 1) {
                throw new Error(
                    `Query parameter ${key} has ${value.length} values (${JSON.stringify(value)})`,
                );
            }
            return value[0];
        }
        return value;
    };
}
