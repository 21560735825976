import { FC, useCallback, useState } from 'react';

import { Icon } from '@common/atoms/Icon';
import { Link } from '@common/atoms/Link';
import { Logo } from '@common/atoms/Logo';
import { NavItem } from '@common/clients/api';
import { InternalLink, Route } from '@web/atoms/InternalLink';
import { ComponentList as NavItemList } from '@web/molecules';
import { ExpandableSearchBar } from '@web/molecules/ExpandableSearchBar';

import styles from './SubNavigation.module.scss';

export interface Props {
    items?: NavItem[];
    currentSearch?: string;
    currentNavItem?: NavItem;
    onSubmitSearch: (searchText: string) => void;
    menuView?: 'mobile' | 'desktop' | '';
}

export const SubNavigation: FC<Props> = ({
    items,
    currentSearch = '',
    onSubmitSearch,
    menuView,
    currentNavItem,
}) => {
    const [state, setState] = useState<{ expandedSearch: boolean }>({
        expandedSearch: false,
    });

    const expandSearch = useCallback(() => {
        setState((prevState) => ({ ...prevState, expandedSearch: !prevState.expandedSearch }));
    }, []);

    const { expandedSearch } = state;

    return (
        <aside className={styles.SubNavigation}>
            <nav
                id="sub-nav"
                className={expandedSearch ? styles.collapsed : ''}
                role="navigation"
                aria-label="sub-navigation"
            >
                <InternalLink
                    className={'/' === currentNavItem?.url ? styles['home-icon-active'] : ''}
                    key="Homepage"
                    route={Route.Homepage}
                >
                    <Icon.home />
                </InternalLink>
                {items?.length ? (
                    <NavItemList
                        ComponentItem={(item: NavItem) => (
                            <li className={item.id === currentNavItem?.id ? styles['is-active'] : ''}>
                                <Link className={styles[item.class ?? item.name]} href={item.url}>
                                    {item.name === 'pro' ? <Logo.pro /> : item.name}
                                </Link>
                            </li>
                        )}
                        propsList={items}
                    />
                ) : null}
            </nav>
            <ExpandableSearchBar
                variant={menuView === 'mobile' ? 'small' : 'default'}
                additionalClass={`${styles['search-area']} ${expandedSearch ? styles.expanded : ''}`}
                currentSearch={currentSearch}
                onSubmit={onSubmitSearch}
                isInverted={true}
                onExpand={expandSearch}
            />
        </aside>
    );
};
