import {
    FC,
    MouseEvent as ReactMouseEvent,
    ReactNode,
    UIEvent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';

import { Icon } from '@common/atoms/Icon';

import { Item } from './types';

import styles from './MainDropdown.module.scss';

export interface Props {
    items: Item[];
    defaultItem?: Item;
    header?: ReactNode;
    isInverted?: boolean;
    isExpandToTop?: boolean;
    isCentered?: boolean;
    onChangeDropdown?: (selection: Item) => Promise<void>;
}

export interface TriggerProps {
    selectedItem?: Item;
    header?: ReactNode;
}

const TriggerContent: FC<TriggerProps> = ({ selectedItem, header }) => {
    if (header) return <>{header}</>;

    return <span className={styles.text}>{selectedItem ?? ' - '}</span>;
};

export const MainDropdown: FC<Props> = (props) => {
    // ListenerRef to check if listener has been added in between renders
    const listenerRef = useRef<boolean | undefined>(undefined);
    const dropdownRef = useRef<HTMLUListElement>(null);

    const [selectedItem, setSelectedItem] = useState<Item>(props.defaultItem ?? props.items[0]);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    // Memoize clickEvent handler so React can do "internal equality checks" and remove listener
    const handleClickEvent = useCallback(
        (e: MouseEvent | UIEvent) => {
            e.preventDefault();
            const parent = dropdownRef.current;
            const target = e.target as HTMLLIElement;
            const child = parent?.contains(target);
            const differentChild = child && 'title' in target && target?.title !== selectedItem;

            // Update state if selected item is a different child-node of the parent
            if (differentChild) {
                setSelectedItem(target.title);

                if (props?.onChangeDropdown) {
                    props.onChangeDropdown(target.title);
                }
            }
            setIsExpanded(false);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.onChangeDropdown, selectedItem],
    );

    // Add & remove clickEvent handler immediately, using listenRef to check if listener
    // was added or removed in previous render,
    useEffect(() => {
        if (isExpanded && !listenerRef.current) {
            listenerRef.current = true;
            window.addEventListener('mousedown', handleClickEvent, true);
        } else if (!isExpanded && listenerRef.current) {
            listenerRef.current = false;
            window.removeEventListener('mousedown', handleClickEvent, true);
        }
        return () => {
            listenerRef.current && window.removeEventListener('mousedown', handleClickEvent, true);
        };
    }, [isExpanded, handleClickEvent]);

    const onClick = (e: ReactMouseEvent) => {
        e.preventDefault();
        setIsExpanded(!isExpanded);
    };

    const classes = [styles.MainDropdownContainer];
    if (isExpanded) classes.push(styles.expanded);
    if (props.isInverted) classes.push(styles.inverted);
    if (props.isExpandToTop) classes.push(styles['expand-to-top']);
    if (props.isCentered) classes.push(styles.centered);

    return (
        <div className={classes.join(' ')}>
            <div onMouseDown={onClick} className={styles.trigger}>
                <TriggerContent header={props.header} selectedItem={selectedItem} />
                <span className={styles.arrow}>
                    <Icon.down />
                </span>
            </div>
            <div className={`${styles.MainDropdown} ${isExpanded ? styles.active : ''}`}>
                <ul ref={dropdownRef} onMouseDown={handleClickEvent}>
                    {isExpanded &&
                        props.items.map((item, i: number) => {
                            const value = item as string;
                            return (
                                <li
                                    key={`dd-${i}`}
                                    title={value}
                                    className={`${styles['list-item']} ${
                                        selectedItem === value ? styles.selected : ''
                                    }`}
                                >
                                    {value}
                                </li>
                            );
                        })}
                </ul>
            </div>
        </div>
    );
};
