import { FC, useState } from 'react';

import styles from './HamburgerIcon.module.scss';

export interface Props {
    onClick: () => void;
    isInverted?: boolean;
}

export const HamburgerIcon: FC<Props> = ({ onClick, isInverted = true }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const classes = [styles.HamburgerIcon];
    if (isOpen) classes.push(styles['is-open']);
    if (isInverted) classes.push(styles.inverted);

    const handleClick = () => {
        if (onClick) onClick();
        setIsOpen(!isOpen);
    };
    return (
        <span className={classes.join(' ')} onClick={handleClick}>
            <span className={styles['first-stripe']} />
            <span className={styles['second-stripe']} />
            <span className={styles['third-stripe']} />
        </span>
    );
};
