import { useEffect, useState } from 'react';

export enum MediaQuery {
    xs = 23.3,
    s = 25.8,
    m = 46.8,
    l = 65,
    xl = 83.6,
    xxl = 100,
    xxxl = 120,
}

export enum MediaQueryDirection {
    below = 'below',
    above = 'above',
}

interface Props {
    direction: MediaQueryDirection;
    size: MediaQuery;
}

const getMatches = (query: string): boolean => {
    // Prevents SSR issues
    if (typeof window !== 'undefined') {
        return window.matchMedia(query).matches;
    }

    return false;
};

export const useResponsive = ({ direction, size }: Props) => {
    const query = `(${direction === MediaQueryDirection.below ? 'max' : 'min'}-width: ${size}em)`;

    const [matches, setMatches] = useState<boolean>(getMatches(query));

    function handleChange() {
        setMatches(getMatches(query));
    }

    useEffect(() => {
        const matchMedia = window.matchMedia(query);

        // Triggered at the first client-side load and if query changes
        handleChange();

        // Listen matchMedia
        if (matchMedia.addListener) {
            matchMedia.addListener(handleChange);
        } else {
            matchMedia.addEventListener('change', handleChange);
        }

        return () => {
            if (matchMedia.removeListener) {
                matchMedia.removeListener(handleChange);
            } else {
                matchMedia.removeEventListener('change', handleChange);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [size]);

    return matches;
};
