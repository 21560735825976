import { NotificationCountContainer, NotificationService } from '@common/clients/api';
import { ApiBaseRequest } from '@common/clients/request';
import { ContextData, defaultCatch } from '@common/defaults';

export const fetchUnreadCountNotifications = async (
    contextData: ContextData,
    isClientSide: boolean,
): Promise<NotificationCountContainer | undefined> => {
    if (!contextData.userID) return { count: 0 }; // Anonymous visitors that are not logged in.

    const request = new ApiBaseRequest(contextData, isClientSide);
    const notificationService = new NotificationService(request);

    const response = await notificationService
        .getUnreadNotificationsCountNotificationByPlatform({
            platform: contextData.platform.id,
            userId: contextData.userID,
        })
        .catch(defaultCatch);

    return response;
};
