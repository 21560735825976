import { FC, useState } from 'react';
import Image, { ImageLoaderProps, ImageProps } from 'next/image';

import { WebpWidth, webpWidths } from '@common/types/WebpWidth';
import { useContextData } from '@common/useContextData';

import { StaticImage } from '../StaticImage';

export interface Props extends Partial<ImageProps> {
    src?: string;
    defaultSize: WebpWidth;
    ratio?: number;
    fallbackSrc?: string;
}

export enum DefaultDynamicSizes {
    /**
     *  On mobile devices we use 60vw instead of 100vw, since mobile devices are usually retina screens anyway
     *  Then we use 30vw, which is usually large enough and doesn't end up using unnecessary large sizes
     *  As max size we use 600px, which doesn't exist, but picks 1200px on retina instead of 1800 which is too large
     */
    TwoThird = '(max-width: 83.6em) 60vw, (max-width: 100em) 30vw, 600px',
    /**
     *  On mobile devices we use 60vw instead of 100vw, since mobile devices are usually retina screens anyway
     *  Then we use 15vw, which is usually large enough and doesn't end up using unnecessary large sizes
     *  As max size we use 450px, since the space will never be larger than 450px wide and results in 900px on retina
     */
    OneThird = '(max-width: 83.6em) 60vw, (max-width: 100em) 15vw, 450px',
}

const imageLoader = (props: ImageLoaderProps) =>
    props.src.replace('{WebpWidth}', `${getRightSize(props.width)}`);

const getRightSize = (width: number): number => {
    const result = webpWidths.reduce((prev, curr) => {
        return Math.abs(curr - width) < Math.abs(prev - width) ? curr : prev;
    });
    return result;
};

export const WebpImage: FC<Props> = ({ defaultSize, ratio, alt, src, fallbackSrc, ...props }) => {
    const { platform } = useContextData();
    const [useFallback, setUseFallback] = useState<boolean>(!src);
    const width = props.fill === true ? undefined : defaultSize;
    const height = width && ratio ? width / ratio : undefined;

    const stylingProps: Partial<ImageProps> = {
        width,
        height: props.fill === true ? undefined : height,
        style: {
            objectFit: 'cover',
            objectPosition: 'top center',
            aspectRatio: width && ratio ? `${ratio * 1000} / 1000` : undefined,
        },
    };

    return !useFallback ? (
        <Image
            alt={alt || ''}
            loader={imageLoader}
            src={src || ''}
            onError={() => {
                setUseFallback(true);
            }}
            {...stylingProps}
            {...props}
        />
    ) : (
        <StaticImage // The fallback image per platform
            alt={`fallback image ${alt}`}
            src={fallbackSrc || `/images/${platform.id}/placeholder.jpg`}
            {...stylingProps}
            {...props}
        />
    );
};
